import React, { useContext } from "react";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import { Download, Eye, Settings, AtSign } from "react-feather";
import QrListAllContext from "../../context/QrListAllContext/QrListAllContext";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import DataNotFound from "../../DataNoteFound/DataNotFound";
import moment from "moment";
import Switch from "react-js-switch";
import { MuiTabelStyle, QR_LIST, QR_STATUS, USER_ROLE } from "../../constant";
import { Zoom, toast } from "react-toastify";
import { qrEnableDisable } from "../../api/QR List All Api/QrListAllApi";
import { userLogout } from "../../auth/Logout";
import { useNavigate } from "react-router";
import SocketContext from "../../context/SocketContext/SocketContext";

const QrListAllItem = (props) => {
  const {
    openModel,
    sorting,
    setPage,
    setPageSize,
    pageSize,
    page,
    record,
    downloadQrIcon,
    getData,
  } = props;

  const {
    qrAll,
    setFilter,
    filter,
    createdAtFilter,
    setCreatedAtFilter,
    expireAtFilter,
    setExpireAtFilter,
    statusFilter,
    setStatusFilter,
    qrIdFilter,
    setQrIdFilter,
    setRenewModal,
    setResetQrPasswordModal,
    setQrId,
    setChangeDomainModal,
    setDomainDetails,
  } = useContext(QrListAllContext);
  const history = useNavigate();
  const { socket } = useContext(SocketContext);

  const switch_onChange_handle = async (qrId, status) => {
    let formData;
    formData = {
      qrId: qrId,
      status: status === 2 ? 1 : 2,
    };
    try {
      const { data } = await qrEnableDisable(formData);
      if (data.status === 200) {
        status === 1
          ? toast.error(data.message, {
              autoClose: 1000,
              transition: Zoom,
            })
          : toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
        getData();
      } else {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const defaultColumns = [
    {
      flex: 0.01,
      minWidth: 60,
      field: "sNo",
      headerName: "S.No.",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      cellClassName: "sticky",
    },
    {
      flex: 0.1,
      minWidth: 130,
      field: "title",
      headerName: "TITLE",
      renderCell: (params) => params.row.qrOrder.title,
      headerClassName: "titleHeader", // Apply custom class to the heade
      headerStyle: {
        position: "sticky",
        left: 0, // Adjust this based on the actual position of the title column
        backgroundColor: "#fff", // Ensure the background matches your DataGrid theme
        zIndex: 102, // Ensure it's above the cell z-index
      },
    },
    {
      flex: 0.1,
      minWidth: 100,
      sortable: false,
      field: "link",
      headerName: "LINK",
      renderCell: (params) => (
        <a
          href={
            params.row.qrOrder.orgDetail.qrDomain
              ? "https://" +
                params.row.qrOrder.orgDetail.qrDomain +
                "." +
                params.row.qrOrder.orgDetail.domain +
                "/" +
                params.row.hwId
              : "https://" +
                "qr" +
                "." +
                params.row.qrOrder.orgDetail.domain +
                "/" +
                params.row.hwId
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          {params.row.hwId}
        </a>
      ),
    },
    // {
    //   flex: 0.1,
    //   minWidth: 130,
    //   sortable: false,
    //   field: "subDomainName",
    //   headerName: "WEBSITE",
    //   renderCell: (params) => (
    //     <a
    //       href={params.row.subDomainName}
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       {params.row?.subDomainName ? params.row?.subDomainName : "-"}
    //     </a>
    //   ),
    // },
    {
      flex: 0.1,
      minWidth: 100,
      sortable: false,
      field: "cardlogin",
      headerName: "ADMIN LOGIN",
      renderCell: (params) => (
        <a
          href={
            "https://" +
            "admin." +
            params.row.qrOrder.orgDetail.domain +
            "/" +
            params.row.hwId
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          {"Login"}
        </a>
      ),
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: "webData",
      headerName: "EMAIL",
      renderCell: (params) =>
        params?.row?.webData?.userEmail ? params?.row?.webData?.userEmail : "-",
    },
    {
      flex: 0.2,
      minWidth: 100,
      sortable: false,
      field: "domain",
      headerName: "DOMAIN",
      renderCell: (params) => (
        <>
          {params.row?.webData?.multiWebData?.webId ? (
            <a
              href={"https://" + params.row?.webData?.multiWebData?.domain}
              target="_blank"
              rel="noopener noreferrer"
            >
              {params.row?.webData?.multiWebData?.domain || "-"}
            </a>
          ) : (
            <a
              href={"https://" + params.row?.webData?.webData?.domain}
              target="_blank"
              rel="noopener noreferrer"
            >
              {params.row.webData?.webData?.domain || "-"}
            </a>
          )}
        </>
      ),
    },
    // {
    //   flex: 0.1,
    //   minWidth: 130,
    //   field: "name",
    //   headerName: "CREATED BY",
    //   renderCell: (params) => params.row.qrOrder.usrDetail.name,
    // },
    {
      flex: 0.1,
      minWidth: 130,
      field: "createdAt",
      headerName: "CREATED AT",
      renderCell: (params) =>
        moment(params.row.createdAt).format("DD MMM, YYYY HH:mm"),
    },
    {
      flex: 0.1,
      minWidth: 130,
      field: "expiredAt",
      headerName: "EXPIRED AT",
      renderCell: (params) =>
        moment(params.row.expiredAt).format("DD MMM, YYYY HH:mm"),
    },

    {
      flex: 0.1,
      minWidth: 130,
      field: "qrStatus",
      headerName: "QR STATUS",
      renderCell: (params) =>
        params.row.qrStatus === QR_STATUS.QR_GENERATED
          ? "NOT REGISTERED"
          : "REGISTERED",
    },
    {
      flex: 0.1,
      minWidth: 150,
      field: "renew",
      headerName: "RE-NEW",
      renderCell: (params) =>
        new Date() > new Date(params.row.expiredAt) ? (
          <Button
            onClick={() => {
              setRenewModal(true);
              setQrId(params.row.qrId);
            }}
          >
            Renew
          </Button>
        ) : (
          moment(params.row.expiredAt).format("DD MMM, YYYY HH:mm")
        ),
    },
    {
      flex: 0.1,
      minWidth: 130,
      field: "status",
      headerName: "ACTIVE",
      renderCell: (params) => (
        <Switch
          value={params.row.status === 1 ? true : false}
          onChange={() =>
            switch_onChange_handle(params.row.qrId, params.row.status)
          }
        />
      ),
    },
    // {
    //   flex: 0.1,
    //   minWidth: 130,
    //   field: "status",
    //   headerName: "ACTIVE",
    //   renderCell: (params) =>
    //     params.row.status === QR_STATUS.QR_GENERATED ? (
    //       <p style={{ color: "green", fontWeight: "700" }}>Active</p>
    //     ) : (
    //       <p style={{ color: "red", fontWeight: "700" }}>In Active</p>
    //     ),
    // },

    {
      flex: 0.2,
      minWidth: 100,
      field: "actions",
      headerName: "ACTIONS",
      sortable: false,
      renderCell: (params) => (
        // console.log("multiWebData >>", params.row?.webData.webData),
        <>
          {(params.row?.webData?.webData?.webId ||
            params.row?.webData?.multiWebData?.webId) &&
            +localStorage.getItem("roleId") !== USER_ROLE.SELLER && (
              <button
                style={{
                  background: "none",
                  textDecoration: "none",
                  border: "none",
                  color: "rgb(115, 102, 255)",
                }}
                onClick={() => {
                  if (params.row?.webData?.multiWebData?.webId) {
                    setDomainDetails({
                      domain: `https://${params.row?.webData?.webData?.domain}`,
                      webId: params.row?.webData.multiWebData.webId,
                      mainDomain: params.row?.qrOrder?.orgDetail?.domain,
                    });
                  } else {
                    setDomainDetails({
                      domain: `https://${params.row?.webData?.webData?.domain}`,
                      webId: params.row?.webData?.webData?.webId,
                      mainDomain: params.row?.qrOrder?.orgDetail?.domain,
                    });
                  }
                  setChangeDomainModal(true);
                }}
                type="button"
              >
                <AtSign style={{ width: "19px" }} />
              </button>
            )}
          <button
            style={{
              background: "none",
              textDecoration: "none",
              border: "none",
              color: "rgb(115, 102, 255)",
            }}
            onClick={() => {
              setQrId(params.row.qrId);
              setResetQrPasswordModal(true);
            }}
          >
            <Settings style={{ width: "19px" }} />
          </button>
          <button
            style={{
              background: "none",
              textDecoration: "none",
              border: "none",
              color: "rgb(115, 102, 255)",
            }}
            onClick={() =>
              openModel(
                params.row.url,
                params.row.qrId,
                params.row.qrOrder.title
              )
            }
          >
            <Eye style={{ width: "19px" }} />
          </button>
          <button
            style={{
              background: "none",
              textDecoration: "none",
              border: "none",
              color: "rgb(115, 102, 255)",
            }}
            onClick={() =>
              downloadQrIcon(
                params.row.qrId,
                params.row.url,
                params.row.qrOrder.title
              )
            }
          >
            <Download style={{ width: "19px" }} />
          </button>
        </>
      ),
    },
  ];

  const handleResetFilters = () => {
    setFilter("");
    setQrIdFilter("");
    setCreatedAtFilter("");
    setExpireAtFilter("");
    setStatusFilter("");
  };

  return (
    <>
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader>
              <h5>{QR_LIST.ORDER_LIST}</h5>
            </CardHeader>
            <Table>
              <thead>
                <tr>
                  <th>
                    <Row>
                      <Col md="2 mb-2">
                        <Form className="search-file">
                          <Label>Customer</Label>
                          <Input
                            className="form-control"
                            type="text"
                            placeholder="Customer Name Search..."
                            value={filter}
                            onInput={(e) => setFilter(e.target.value)}
                            style={{
                              border: "1px solid #ecf3fa",
                              borderRadius: "8px",
                            }}
                          />
                        </Form>
                      </Col>
                      <Col md="2 mb-2">
                        <Form className="search-file">
                          <Label>QR Id</Label>
                          <Input
                            className="form-control"
                            type="text"
                            placeholder="QR Id Search..."
                            value={qrIdFilter}
                            onInput={(e) => setQrIdFilter(e.target.value)}
                            style={{
                              border: "1px solid #ecf3fa",
                              borderRadius: "8px",
                            }}
                          />
                        </Form>
                      </Col>
                      <Col md="2 mb-2">
                        <Form className="search-file">
                          <Label>Created Date</Label>
                          <Input
                            className="form-control"
                            type="date"
                            placeholder="Created At Search..."
                            value={createdAtFilter}
                            onInput={(e) => setCreatedAtFilter(e.target.value)}
                            style={{
                              border: "1px solid #ecf3fa",
                              borderRadius: "8px",
                            }}
                          />
                        </Form>
                      </Col>
                      <Col md="2 mb-2">
                        <Form className="search-file">
                          <Label>Expire Date</Label>
                          <Input
                            className="form-control"
                            type="date"
                            placeholder="Expire At Search..."
                            value={expireAtFilter}
                            onInput={(e) => setExpireAtFilter(e.target.value)}
                            style={{
                              border: "1px solid #ecf3fa",
                              borderRadius: "8px",
                            }}
                          />
                        </Form>
                      </Col>
                      <Col md="2 mb-2">
                        <Form className="search-file">
                          <Label>Status</Label>
                          <select
                            className="form-control"
                            onChange={(e) => setStatusFilter(e.target.value)}
                            value={statusFilter}
                            name="planName"
                          >
                            <option value="">--select--</option>
                            <option value="1">Active</option>
                            <option value="2">In Active</option>
                          </select>
                        </Form>
                      </Col>
                      <Col md="2 mb-2">
                        <Button
                          onClick={() => handleResetFilters()}
                          className="mt-4 "
                        >
                          Reset Filter
                        </Button>
                      </Col>
                      {/* <Col md="2 mb-2">
                        <Form className="search-file">
                          <Input
                            className="form-control"
                            type="text"
                            placeholder="Website Name Search..."
                            value={filter}
                            onInput={(e) => setFilter(e.target.value)}
                            style={{
                              border: "1px solid #ecf3fa",
                              borderRadius: "8px",
                            }}
                          />
                        </Form>
                      </Col> */}
                      {/* <Col md="2 mb-2">
                        <Form className="search-file">
                          <Input
                            className="form-control"
                            type="text"
                            placeholder="Tenure Search..."
                            value={filter}
                            onInput={(e) => setFilter(e.target.value)}
                            style={{
                              border: "1px solid #ecf3fa",
                              borderRadius: "8px",
                            }}
                          />
                        </Form>
                      </Col> */}
                    </Row>
                  </th>
                </tr>
              </thead>
            </Table>
            <Box
              sx={{
                "& .even": {
                  backgroundColor: "#f1f1f1",
                },
                "& .odd": {
                  backgroundColor: "white",
                },
                "& .sticky_cell": {
                  position: "sticky",
                  left: 0,
                  zIndex: 101,
                },
              }}
            >
              {qrAll.length && qrAll.length ? (
                <DataGrid
                  autoHeight
                  pagination
                  sx={MuiTabelStyle}
                  rows={qrAll}
                  rowCount={record}
                  pageSize={pageSize}
                  getRowId={(row) => row.qrId}
                  columns={defaultColumns}
                  page={page - 1}
                  rowsPerPageOptions={[7, 10, 25, 50]}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                  paginationMode="server"
                  disableSelectionOnClick
                  checkboxSelection={false}
                  sortingMode="server"
                  onSortModelChange={sorting}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                  }
                  pinnedColumns={["title"]}
                />
              ) : (
                <DataNotFound />
              )}
            </Box>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default QrListAllItem;
